import jsonServerProvider from 'ra-data-json-server'
import { CreateResult, HttpError, UpdateResult } from 'react-admin';

const base_provider = jsonServerProvider('/api');

export const roleTypesProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {
        params.data.name = JSON.stringify(params.data.name)

        const res = await fetch(`/api/role-types`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: { id: params.data.id } }
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {
        params.data.name = JSON.stringify(params.data.name)

        const res = await fetch(`/api/role-types/${params.data.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.data)
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: { id: params.previousData.id } }
    }
}