import { Datagrid, DeleteButton, EditButton, List, NumberField, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { ImageSourceField } from "../../ImageSource";

const filters = [
  <TextInput label="Search" source="q_Lid" alwaysOn />,
]

export const InertList = (props: any) => {
  return (
    <List {...props} filters={filters}>
      <Datagrid rowClick="show">
        <ImageSourceField
          source="images.image"
          res="thumb"
          height="100px"
          label="Immagine"
        />
        <TextField source="id" label="Nome" />
        <LangListField source="description" label="Descrizione" />
        <NumberField source="diametroPartenza" label="da" />
        <NumberField source="diametroArrivo" label="a" />
        <EditButton />
      </Datagrid>
    </List>
  );
}