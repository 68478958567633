import { useRecordContext } from "react-admin"
import Map, { Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';


export const MapField = ({source}: any) => {

    const record = useRecordContext()

    return <>
        <Map 
            mapLib={maplibregl}
            initialViewState={{
                longitude: record[source]?.lng,
                latitude: record[source]?.lat,
                zoom: 3.5
            }}
            style={{width: 600, height: 400}}
            mapStyle="https://demotiles.maplibre.org/style.json"
        >
            {!!record[source] && <Marker longitude={record[source].lng} latitude={record[source].lat}/>}
        </Map>
    </>
}