import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { LangInput } from "../../LangInput";
import { useEffect, useState } from "react";
import { RichTextInput } from "ra-input-rich-text";

export const CourseEdit = (props: any) => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    fetch("/api/course/types")
      .then((e) => e.json())
      .then((e) => {
        setTypes(e.map((f: any) => ({ id: f.id, name: f.id })));
        console.log(e);
      });
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm>
        <LangInput source="name" label="Nome del corso">
          <TextInput source="description" />
        </LangInput>

        <LangInput source="description" label="Descrizione ">
          <RichTextInput source="description" label="Descrizione" />
        </LangInput>

        <AutocompleteArrayInput
          source="types"
          onCreate={(text) => {
            if (text) {
              setTypes([...types, { id: text, name: text }]);
              return { id: text, name: text };
            }
          }}
          choices={types}
        />

        <BooleanInput source="visible" label="Visibile" defaultValue={true} />
        <DateTimeInput source="date" label="Data" />
        <NumberInput source="price" label="Prezzo" />

        <TextInput source="language" label="Lingua del corso" />
        <NumberInput source="max_partecipants" label="Numero massimo di partecipanti" />
        <BooleanInput source="unlimited" label="Numero illimitato di partecipanti" defaultValue={false} />
        <BooleanInput source="isOnline" label="Corso online" defaultValue={false} />
        <TextInput source="address" label="Indirizzo" />
        <TextInput source="city" label="Città" />
        <TextInput source="country" label="Paese" />

        <ImageInput
          accept="image/*"
          maxSize={20 * 1024 * 1024}
          source="images.main_image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

      </SimpleForm>
    </Edit>
  );
};
