import { Datagrid, EditButton, List, TextField, TextInput } from "react-admin";
import { ImageSourceField } from "../../ImageSource";
import { StringArrayField } from "../../StringArrayField";
import { CheckboxField } from "../../CheckboxField";

const filters = [
    <TextInput label="Search" source="q_Lname" alwaysOn/>
]

export const MachineryList = (props: any) => {
    return (
      <List {...props} filters={filters}>
        <Datagrid rowClick="show">
          <ImageSourceField
            source="images.main_image"
            res="thumb"
            height="100px"
            label="Immagine"
          />
          <TextField source="name" label="Nome" />
          <StringArrayField source="types" />
          <TextField source="usedHour" label="Ore di utilizzo" />
          <TextField source="km" label="Km" />
          <TextField source="lung_A" label="Lato A" />
          <TextField source="lung_B" label="Lato B" />
          <TextField source="altezza" label="Altezza" />
          <TextField source="larghezza" label="Larghezza" />
          <CheckboxField source="available" />
          <TextField source="pieces" label="N. Pezzi" />
          <EditButton />
        </Datagrid>
      </List>
    );
}