import { Box, FormLabel } from "@mui/material";
import { ArrayField, Button, Datagrid, DateField, List, Show, SimpleShowLayout, TextField, useDataProvider, useRecordContext } from "react-admin";

export const UserShow = (props: any) => {
   const dataProvider = useDataProvider();

    return (

        <Show {...props}>
            <SimpleShowLayout>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>

                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField source="name" label="name" style={{ fontWeight: "bold", fontSize: 32 }} />
                            <TextField source="surname" label="surname" style={{ fontWeight: "bold", fontSize: 32 }} />
                        </Box>
                        <FormLabel sx={{ fontWeight: "bold", fontSize: 32 }}>-</FormLabel>
                        <TextField source="company.ragione_sociale" style={{ fontWeight: "bold", fontSize: 32 }} />
                    </Box>

                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Indirizzo:</FormLabel>
                        <TextField source="address" />
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Luogo:</FormLabel>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <TextField source="city" />
                            <Box>
                                <TextField source="cap" />,
                            </Box>
                        </Box>
                        <TextField source="province" />
                        <TextField source="country" />
                    </Box>

                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Telefono:</FormLabel>
                        <TextField source="telephone" />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box>
                            <FormLabel style={{ fontWeight: "bold", fontSize: 32, color: "black" }}>Azienda: </FormLabel>
                            <TextField source="company.ragione_sociale" style={{ fontWeight: "bold", fontSize: 32 }} />
                        </Box>
                        <Box>
                            <FormLabel>Pec: </FormLabel>
                            <TextField source="company.pec" />
                        </Box>
                        <Box>
                            <FormLabel>P. Iva: </FormLabel>
                            <TextField source="company.vat_code" />
                        </Box>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <FormLabel>Indirizzo:</FormLabel>
                            <TextField source="company.address" />
                        </Box>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <FormLabel>Luogo:</FormLabel>
                            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                <TextField source="company.city" />
                                <Box>
                                    <TextField source="company.cap" />,
                                </Box>
                            </Box>
                            <TextField source="company.province" />
                            <TextField source="company.country" />
                        </Box>

                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <FormLabel>Telefono:</FormLabel>
                            <TextField source="company.telephone" />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <FormLabel style={{ fontWeight: "bold", fontSize: 32, color: "black" }}>Documenti firmati</FormLabel>
                        <ArrayField source="docs">
                            <Datagrid>
                                <Box>
                                    <FormLabel>Descrizione: </FormLabel>
                                    <TextField source="description" />
                                </Box>
                                <Box>
                                    <FormLabel>Data creazione: </FormLabel>
                                    <DateField source="dataCreazione" />
                                </Box>
                            </Datagrid>
                        </ArrayField>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <FormLabel style={{ fontWeight: "bold", fontSize: 32, color: "black" }}>Richiesta preventivi</FormLabel>
                        <ArrayField source="orders">
                            <Datagrid>
                                <Box>
                                    <FormLabel>Codice: </FormLabel>
                                    <TextField source="order_code" />
                                </Box>
                                <Box>
                                    <FormLabel >Tipo: </FormLabel>
                                    <DateField source="type" />
                                </Box>
                            </Datagrid>
                        </ArrayField>
                    </Box>
                </Box>
            </SimpleShowLayout>
        </Show>
    );
};
