import { combineDataProviders } from "react-admin";
import { blockProvider } from "./blockProvider";
import { finishingProvider } from "./finishingProvider";
import { materialProvider } from "./materialProvider";
import { projectProvider } from "./projectProvider";
import { slabProvider } from "./slabProvider";
import { qualityProvider } from "./qualityProvider";
import { healthstateProvider } from "./healthStateProvider";
import { newsProvider } from "./news.provider";
import { floorProvider } from "./floorProvider";
import { inertProvider } from "./inertProvider";
import { lavoratiProvider } from "./lavoratiProvider";
import { branchProvider } from "./branchProvider";
import { machineryProvider } from "./machineryProvider";
import { cassaProvider } from "./cassaProvider";
import { userProvider } from "./userProvider";
import { companyProvider } from "./companyProvider";
import { roleTypesProvider } from "./roleTypesProvider";
import { courseProvider } from "./courseProvider";

export default combineDataProviders((resource) => {
  switch (resource) {
    case "finishing":
      return finishingProvider;
    case "block":
      return blockProvider;
    case "material":
      return materialProvider;
    case "slab":
      return slabProvider;
    case "project":
      return projectProvider;
    case "quality":
      return qualityProvider;
    case "healthstate":
      return healthstateProvider;
    case "news":
      return newsProvider;
    case "cassa":
      return cassaProvider;
    case "floor":
      return floorProvider;
    case "inert":
      return inertProvider;
    case "lavorati":
      return lavoratiProvider;
    case "branch":
      return branchProvider;
    case "machinery":
      return machineryProvider;
    case "user":
      return userProvider
    case "company":
      return companyProvider
    case "role-types":
      return roleTypesProvider
    case "course":
      return courseProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});
