import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { LangInput } from "../../LangInput";
import { useEffect, useState } from "react";
import { RichTextInput } from "ra-input-rich-text";

export const MachineryEdit = (props: any) => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    fetch("/api/machinery/types")
      .then((e) => e.json())
      .then((e) => {
        setTypes(e.map((f: any) => ({ id: f.id, name: f.id })));
        console.log(e);
      });
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" required />
        <TextInput source="brand" label="Marca" required />
        <TextInput source="constructionYear" label="Anno di costruzione" />
        <AutocompleteArrayInput
          source="types"
          onCreate={(text) => {
            if (text) {
              setTypes([...types, { id: text, name: text }]);
              return { id: text, name: text };
            }
          }}
          choices={types}
          isRequired
        />

        <LangInput source="description" label="Descrizione">
          <TextInput source="description" />
        </LangInput>

        <LangInput source="status" label="Stato macchinario/macchina">
          <TextInput source="status" />
        </LangInput>
        <NumberInput source="usedHour" label="Ore di utilizzo" />
        <NumberInput source="km" label="Km fatti" />

        <NumberInput source="lung_A" label="Lato A" />
        <NumberInput source="lung_B" label="Lato B" />
        <NumberInput source="altezza" label="Altezza" />
        <NumberInput source="larghezza" label="Larghezza" />
        <NumberInput source="price" label="Prezzo" />

        <BooleanInput source="available" label="Disponibile" />
        <RichTextInput source="reasonNotAvailable" label="Motivazione" />

        <NumberInput source="pieces" label="N. Pezzi" />
        <ImageInput
          accept="image/*"
          maxSize={20 * 1024 * 1024}
          source="images.main_image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          accept="image/*"
          maxSize={20 * 1024 * 1024}
          multiple
          source="images.aux"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput
          isRequired={false}
          accept="application/pdf"
          source="pdfs.pdf"
          label="Documento"
        >
          <FileField source="src" title="documento.pdf" />
        </FileInput>
        <FileInput accept={"video/mp4"} source="video" label="Video">
          <FileField source="src" title="video.mp4" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
