import { BooleanInput, Create, Edit, EditButton, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin"
import { LangInput } from "../../LangInput"

export const RoleTypesEdit = (props: any) => {
    return (
        <Edit {...props} actions={false}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <LangInput source="name" label="Nome">
                    <TextInput source="description" label="Descrizione" />
                </LangInput>

                <NumberInput source="order" label="Ordine" />
                <BooleanInput source="visible" label="Visibile" />
                <BooleanInput source="isPrivateType" label="Private?" defaultChecked={false} />
            </SimpleForm>
        </Edit>
    )
}
const CustomToolbar = (props: any) => <Toolbar {...props}><SaveButton /></Toolbar>
