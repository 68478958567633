import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, DeleteResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const floorProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {
        delete params.data.finitura
        delete params.data.qualita

        if (params.data.bancata.id)
            params.data.bancataId = params.data.bancata.id;
        console.log(params.data)
        if (!params.data.cassa)
            throw new Error("Inserire cassa");

        if (params.data.finishing.id)
            params.data.finishingId = params.data.finishing.id;

        if (params.data.quality.id)
            params.data.qualityId = params.data.quality.id

        if (params.data.materiale.id)
            params.data.materiale = params.data.materiale.id

     
        params.data.cassaId = params.data.cassa.id;
        params.data.posizione = null;
        params.data.prezzo = 0;
        delete params.data.bancata;
        delete params.data.finishing;
        delete params.data.quality;
        delete params.data.materiale;

        delete params.data.cassa;
        const res = await fetch(`/api/floor/${params.data.cassaId}/${params.data.numero_pavimento}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status)
        }
        return { data: { id: params.cassaId } }
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

        delete params.data.finitura
        delete params.data.qualita

        if (params.data.bancata.id)
            params.data.bancataId = params.data.bancata.id;
        if (!params.data.cassa.id)
            throw new Error("Inserire cassa");

        if (params.data.finishing.id)
            params.data.finishingId = params.data.finishing.id;

        if (params.data.quality.id)
            params.data.qualityId = params.data.quality.id

        if (params.data.materiale.id)
            params.data.materiale = params.data.materiale.id

        delete params.data.bancata;
        delete params.data.finishing;
        delete params.data.quality;
        delete params.data.materiale;
        params.data.cassaId = params.data.cassa.id;
        params.data.posizione = params.data.cassa.posizione;
        params.data.prezzo = params.data.cassa.prezzo;

        delete params.data.cassa;
        const res = await fetch(`/api/floor/${params.data.cassaId}/${params.data.numero_pavimento}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    },
    delete: async (resource: any, params: any): Promise<DeleteResult<any>> => {
        console.log(params)
        params.previousData.cassa = params.previousData.cassa.id;
        const res = await fetch(`/api/floor/${params.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: params.id,
            })
        })

        if (res.status !== 200) {
            let body = await res.json();
            throw new HttpError(body.message, res.status, "");
        }
        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    },
    
}