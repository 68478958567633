import { useEffect, useState } from "react"
import { AutocompleteArrayInput, Create, ImageField, ImageInput, NumberInput, ReferenceArrayInput, SimpleForm, TextInput } from "react-admin"
import { RichTextInput } from 'ra-input-rich-text';
import { LangInput } from "../../LangInput";

export const MaterialCreate = (props: any) => {
    const [types, setTypes] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {
        fetch("/api/material/types").then(e => e.json()).then(e => {
            setTypes(e.map((f: any) => ({ id: f.id, name: f.id })))
            console.log(e)
        })
    }, [])


    return <Create {...props}>
        <SimpleForm>
            <TextInput source="id" label="Nome" required />

            <NumberInput source="ord_key" label="Order Key" defaultValue={1000} />

            <LangInput source="name" label="Nome">
                <TextInput source="description" />
            </LangInput>

            <LangInput source="commercial_name" label="Nome Commerciale">
                <TextInput source="description" />
            </LangInput>

            <TextInput source="color" label="Colore" required />

            <LangInput source="origin" label="Origine">
                <TextInput source="description" />
            </LangInput>

            <AutocompleteArrayInput source='type' label="Famiglia" onCreate={(text) => {
                if (text) {
                    setTypes([...types, { id: text, name: text }])
                    return { id: text, name: text }
                }
            }} choices={types} />

            <LangInput source="description" label="Descrizione">
                <RichTextInput source="description" label="Descrizione" />
            </LangInput>

            <LangInput source="available_finishings" label="Finiture">
                <TextInput source="description" />
            </LangInput>

            <ReferenceArrayInput source="related" reference="material">
                <AutocompleteArrayInput optionText={(r) => r.id} optionValue="id" />
            </ReferenceArrayInput>

            <ImageInput accept="image/*" maxSize={10 * 1024 * 1024} source="main_image">
                <ImageField source="src" title="title" />
            </ImageInput>

            <ImageInput accept="image/*" maxSize={10 * 1024 * 1024} multiple source="images">
                <ImageField source="src" title="title" />
            </ImageInput>


        </SimpleForm>
    </Create>
}