import { BooleanInput, Button, Confirm, DeleteButton, Edit, SimpleForm, TextInput, Toolbar, useDelete, useRecordContext } from "react-admin"
import { CheckboxField } from "../../CheckboxField"
import { Box, FormLabel } from "@mui/material";
import { useState } from "react";

export const UserEdit = (props: any) => {

    return <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="id" disabled />
            <Box sx={{ display: "flex", gap: 2 }}>
                <TextInput source="name" disabled />
                <TextInput source="surname" disabled />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
                <TextInput source="address" disabled />
                <TextInput source="city" disabled />
                <TextInput source="cap" disabled />
                <TextInput source="province" disabled />
                <TextInput source="country" disabled />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
                <TextInput source="company.id" />
                <TextInput source="company.ragione_sociale" disabled />
            </Box>
            <BooleanInput source="enabled" label="Utente abilitato" />

        </SimpleForm>
    </Edit>
}

const CustomToolbar = (props: any) => <Toolbar {...props}> <DeleteButton mutationMode="pessimistic" /></Toolbar>