import { stringify } from 'query-string';
import jsonServerProvider from 'ra-data-json-server'
import { DatagridBody, fetchUtils, GetListResult, HttpError, UpdateResult, useListParams } from 'react-admin'

const base_provider = jsonServerProvider('/api')

export const userProvider = {
    ...base_provider,
    getList: async (resource: any, params: any): Promise<GetListResult<any>> => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `/api/user/list?${stringify(query)}`;


        return fetchUtils.fetchJson(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers?.get('X-Total-Count')?.split('/').pop() ?? "10",
                    10
                ),
            };
        });
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {
        delete params.data.orders
        delete params.data.docs

        const res = await fetch(`/api/user/${params.data.id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    },
    invalidRefreshToken: async (userId: string) => {
        const res = await fetch(`/api/auth/invalid-refresh-token`, {
            method: "PATCH",
            body: JSON.stringify({ userId }),
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { message: "success" }
    }
}