import { ArrayInput, AutocompleteArrayInput, AutocompleteInput, Create, ImageField, ImageInput, NumberInput, ReferenceArrayInput, ReferenceInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin"
import { RichTextInput } from 'ra-input-rich-text';
import { LangInput } from "../../LangInput";
import { MapInput } from "../../MapInput";

export const ProjectCreate = (props: any) => {
    return <Create {...props} redirect="show">
        <SimpleForm>
            <LangInput source="title" label="Titolo">
                <TextInput source="description" required/>
            </LangInput>
            <LangInput source="description" label="Descrizione">
                <RichTextInput source="description" label="Descrizione"/>
            </LangInput>

            <LangInput source="location" label="Località">
                <TextInput source="description" required/>
            </LangInput>
            <LangInput source="nation" label="Nazione">
                <TextInput source="description" required/>
            </LangInput>

            <AutocompleteArrayInput source="service" label="Servizi" choices={[
                { id: 'fornitura', name: 'Fornitura' },
                { id: 'posa', name: 'Posa' }
            ]} />

            
            <TextInput source="type" label="Tipologia di progetto" required/>
            <NumberInput source="ord_key" label="Ordinamento" required/>

            {/* <SelectArrayInput source="service" required label="Servizi" choices={[{id: "fornitura", name: "Fornitura"}, {id: "posa", name: "Posa"}]} /> */}
            
            <ReferenceArrayInput source="related" reference="project" >
                <AutocompleteArrayInput optionText={(r) => r.id} optionValue="id" filterToQuery={(searchText) => ({ q_Lid: searchText })} />
            </ReferenceArrayInput>

            <ArrayInput source="materials" required label="Materiali utilizzati">
                <SimpleFormIterator inline>
                    <ReferenceInput isRequired source="material" reference="material">
                        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
                    </ReferenceInput>
                    <NumberInput source="amount" required label="Quantitativo (MQ)" helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>

            <MapInput source="coordinates"/>

            <ImageInput accept="image/*" maxSize={10*1024*1024} source="main_image" label="Immagine Principale">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={10*1024*1024} multiple source="images" label="Immagine Ausiliarie">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
}