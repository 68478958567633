import { TextInput, List, Datagrid, TextField, EditButton, } from "react-admin";

const filters = [
    <TextInput label="Email" source="q_Lemail" alwaysOn />,
    <TextInput label="Name" source="q_Lname" alwaysOn />,
    <TextInput label="Surname" source="q_Lsurname" alwaysOn />,
    <TextInput label="Paese" source="q_Lcountry" alwaysOn />,

]

export const UserList = (props: any) =>
    <List {...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            {/* <TextField source="id" label="Id" /> */}
            <TextField source="email" label="Email" />
            <TextField source="name" label="Nome" />
            <TextField source="surname" label="Cognome" />
            <TextField source="telephone" label="Telefono" />
            <TextField source="country" label="Paese" />
            {/* <TextField source="company.ragione_sociale" label="Azienda"/> */}
            <EditButton/>
        </Datagrid>
    </List>