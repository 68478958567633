import { useEffect, useState } from "react"
import { AutocompleteArrayInput, DateInput, Edit, ImageField, ImageInput, ReferenceArrayInput, SimpleForm, TextInput } from "react-admin"
import { RichTextInput } from 'ra-input-rich-text';
import { LangInput } from "../../LangInput";
import { useLangContext } from "../../LangContext";

export const NewsEdit = (props: any) => {
    const [tags, setTags] = useState<{id: string, name: string}[]>([])
    const lang = useLangContext()

    useEffect(() => {
        fetch("/api/news/tags").then(e => e.json()).then(e => {
            setTags(e.map((f: any) => ({id: f.id, name: f.id})))
            console.log(e)
        })
    }, [])

    return <Edit {...props}  redirect="show">
        <SimpleForm>
            <LangInput source="title" label="Titolo">
                <TextInput source="description" required/>
            </LangInput>
            <DateInput source="date" label="Data"/>
            <AutocompleteArrayInput source='tags' onCreate={(text) => {
                if(text){
                    setTags([...tags, {id: text, name: text}])
                    return {id: text, name: text}
                }
            }} choices={tags}/>
            <LangInput source="description" label="Descrizione">
                <RichTextInput source="description"/>
            </LangInput>

            <ReferenceArrayInput source="related" reference="news">
                <AutocompleteArrayInput optionText={(record) => lang.getTranslation(record.title, record.id + "")} optionValue="id" />
            </ReferenceArrayInput>

            <ImageInput accept="image/*" maxSize={10*1024*1024} source="images.image" label="Immagine Principale">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={10*1024*1024} multiple source="images.aux" label="Immagine Ausiliarie">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Edit>
}