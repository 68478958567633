import { Create, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"

export const FinishingCreate = (props: any) => {

    return <Create {...props}>
        <SimpleForm>
            <TextInput source="id" required/>

            <LangInput source="name" label="Nome">
                <TextInput source="description" label="Nome"/>
            </LangInput>
        </SimpleForm>
    </Create>
}