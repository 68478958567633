import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin";
import { ImageSourceField } from "../../ImageSource";

const filters = [<TextInput label="Search" source="q_Lid" alwaysOn />];

export const CassaList = (props: any) => {
  return (
    <List {...props} filters={filters}>
      <Datagrid rowClick={"show"}>
        <ImageSourceField
          source="images.main_image"
          res="thumb"
          height="100px"
          label="Immagine"
        />
        <TextField source="id" label="Codice Cassa" />
        <TextField source="posizione" label="Posizione" />
        <TextField source="prezzo" label="Prezzo" />
      </Datagrid>
    </List>
  );
};
