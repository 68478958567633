import { ArrayField, CreateButton, Datagrid, Edit, EditButton, FunctionField, ImageField, List, ReferenceField, Show, ShowButton, SimpleShowLayout, TextField, TextInput } from "react-admin";
import { ImageAuxSourceField, ImageSourceField } from "../../ImageSource";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { LangField } from "../../LangField";
import { useLangContext } from "../../LangContext";
import { Box, FormLabel } from "@mui/material";
import { CheckboxField } from "../../CheckboxField";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn/>
]
export const CassaShow = (props: any) => {
  const lang = useLangContext();

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Box
          display="flex"
          justifyContent={"space-evenly"}
          flexDirection="row"
          alignItems="center"
        >
          <ImageSourceField
            source="images.main_image"
            res="thumb"
            height="300px"
          label="Immagine"
          />
          <Box display={"flex"} flexDirection={"column"}>
            <FormLabel>Id Cassa</FormLabel>
            <TextField source="id" label="Id Cassa" />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <FormLabel>Posizione</FormLabel>
            <TextField source="posizione" />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <FormLabel>Prezzo</FormLabel>
            <TextField source="prezzo" label="Prezzo" />
          </Box>
        </Box>
        <ArrayField source="floors">
          <Datagrid>
            <TextField source="floor.id" label="Codice" />
            <TextField source="floor.numero_pavimento" label="N. Pavimento" />
            <TextField source="floor.selection" label="Selezione" />
            <TextField source="floor.formato_1" label="Formato Uno" />
            <TextField source="floor.formato_2" label="Formato Due" />
            <TextField source="floor.spessore" label="Spessore" />
            <TextField source="floor.quantity" label="Quantità" />
            <TextField source="floor.mq" label="Mq" />
            <TextField source="floor.tipoFormato" label="Tipo Formato" />
            <CheckboxField source="floor.show" />
            <CheckboxField source="floor.isFreeLength" />
            <ReferenceField source="floor.id" reference="floor">
              <EditButton />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
  
};

