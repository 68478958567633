import { RichTextInput } from "ra-input-rich-text"
import { ArrayInput, AutocompleteArrayInput, AutocompleteInput, Edit, ImageField, ImageInput, NumberInput, ReferenceArrayInput, ReferenceInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { MapInput } from "../../MapInput"
import { useLangContext } from "../../LangContext"


export const ProjectEdit = (props: any) => {
    const lang = useLangContext()

    return <Edit {...props}>
        <SimpleForm>
            <LangInput source="title" label="Titolo">
                <TextInput source="description" required />
            </LangInput>
            <LangInput source="description" label="Descrizione">
                <RichTextInput source="description" label="Descrizione" />
            </LangInput>

            <LangInput source="location" label="Località">
                <TextInput source="description" required />
            </LangInput>
            <LangInput source="nation" label="Nazione">
                <TextInput source="description" required />
            </LangInput>

            <AutocompleteArrayInput source="service" label="Servizi" choices={[
                { id: 'fornitura', name: 'Fornitura' },
                { id: 'posa', name: 'Posa' }
            ]} />
            <TextInput source="type" label="Tipologia di progetto" required />
            <NumberInput source="ord_key" label="Ordinamento" required />

            <ReferenceArrayInput source="related" reference="project">
                <AutocompleteArrayInput optionText={(r) => lang.getTranslation(r.title, r.id + "") + "-" + r.id} optionValue="id" filterToQuery={(searchText) => ({ q_Ltitle: searchText })} />
            </ReferenceArrayInput>


            <ArrayInput source="materials" required label="Materiali utilizzati">
                <SimpleFormIterator inline>
                    <ReferenceInput isRequired source="material.id" reference="material">
                        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
                    </ReferenceInput>
                    <NumberInput source="amount" required label="Quantitativo (MQ)" helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>

            <MapInput source="coordinates" />


            <ImageInput accept="image/*" maxSize={10 * 1024 * 1024} source="images.image" label="Immagine Principale">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={10 * 1024 * 1024} multiple source="images.aux" label="Immagine Ausiliarie">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
}