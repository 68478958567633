import { ArrayField, AutocompleteArrayInput, BooleanField, Datagrid, List, NumberField, ReferenceArrayInput, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { StringArrayField } from "../../StringArrayField";

const filters = [
    <TextInput label="Search" source="q_Lname" alwaysOn />
]

export const CourseList = (props: any) =>
    <List {...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <TextField source="id" label="ID" />
            <LangListField source="name" label="Name" />
            <LangListField source="description" label="Description" />
            <NumberField source="price" label="Price" />
            <BooleanField source="isOnline" label="Online" />
            <BooleanField source="visible" label="Visible" />
            <StringArrayField source='types' />

        </Datagrid>
    </List>