import jsonServerProvider from 'ra-data-json-server'
import { CreateResult, UpdateResult } from 'react-admin'
import { resourceLimits } from 'worker_threads'
const base_provider = jsonServerProvider("/api")

export const companyProvider = {
    ...base_provider,
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

        return { data: "" }
    }
}