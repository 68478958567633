import { DeleteButton, Edit, SimpleForm, TextInput, Toolbar } from "react-admin"
import { Box } from '@mui/material'

export const CompanyEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="id" disabled />
            <Box sx={{ display: "flex", gap: 2 }}>
                <TextInput source="address" disabled />
                <TextInput source="city" disabled />
                <TextInput source="cap" disabled />
                <TextInput source="province" disabled />
                <TextInput source="country" disabled />
            </Box>
        </SimpleForm>
    </Edit>
}
const CustomToolbar = (props: any) => <Toolbar {...props}> <DeleteButton mutationMode="pessimistic" /></Toolbar>
