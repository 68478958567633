import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
export const FloorEdit = (props: any) => {

 
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput required source="id" label="ID" />
        <TextInput
          required
          source="numero_pavimento"
          label="Numero Pavimento"
        />
        <ReferenceInput source="cassa.id" isRequired reference="cassa">
          <AutocompleteInput
            label="Cassa"
            isRequired
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <ReferenceInput isRequired source="bancata.id" reference="branch">
          <SelectInput label="Bancata" optionText="id" />
        </ReferenceInput>
        <ReferenceInput source="materiale.id" reference="material">
          <AutocompleteInput
            label="Materiale"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="finishing.id" reference="finishing">
          <AutocompleteInput
            label="Finitura"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="quality.id" reference="quality">
          <AutocompleteInput
            label="Qualità"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <TextInput source="selection" label="Selezione" />
        <NumberInput isRequired source="formato_1" />
        <NumberInput isRequired source="formato_2" />
        <NumberInput isRequired source="spessore" />
        <NumberInput isRequired source="quantity" />

        <BooleanInput source="isFreeLength" label="Pavimento a correre" />
        <NumberInput source="mq" label="Mq" />

        <TextInput source="tipoFormato" label="Tipo Formato" />
        <BooleanInput source="show" label="Visibile" />
      </SimpleForm>
    </Edit>
  );
};
