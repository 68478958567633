import { AutocompleteArrayInput, BooleanInput, Create, Edit, EditButton, FileField, FileInput, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { useState } from "react";

export const InertCreate = (props: any) => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" label="Nome" required />
        <LangInput source="description" label="Descrizione">
          <TextInput source="description" />
        </LangInput>
        <NumberInput source="diametroPartenza" label="Diametro Partenza" />
        <NumberInput source="diametroArrivo" label="Diametro Arrivo" />
        <TextInput source="type" label="Tipo" required />

        <BooleanInput source="misto" label="Misto?" />
        <NumberInput source="ord_key" label="ordine" />
        <ImageInput
          accept="image/*"
          maxSize={20 * 1024 * 1024}
          source="image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput
          isRequired={false}
          accept="application/pdf"
          source="pdfs.pdf"
          label="Documento"
        >
          <FileField source="src" title="documento.pdf" />
        </FileInput>
        <FileInput accept={"video/mp4"} source="video" label="Video">
          <FileField source="src" title="video.mp4" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}