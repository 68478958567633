import { AutocompleteInput, BooleanInput, Edit, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin"



export const BlockEdit = (props: any) => {
    return (
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="id" label="Codice Blocco" required />

          <TextInput source="materialeId" label="Materiale" required/>

          <ReferenceInput isRequired source="quality.id" reference="quality">
            <SelectInput label="Qualità" optionText="id" />
          </ReferenceInput>

          <NumberInput required source="lung_A" label="Lunghezza A" />
          <NumberInput required source="lung_B" label="Lunghezza B" />
          <NumberInput required source="altezza" label="Altezza" />
          <NumberInput required source="larghezza" label="Larghezza" />
          <ReferenceInput isRequired source="bancata.id" reference="branch">
            <SelectInput label="Bancata" optionText="id" />
          </ReferenceInput>
          <ReferenceInput isRequired source="SR.id" reference="healthstate">
            <SelectInput label="Stato" optionText="description" />
          </ReferenceInput>

          <BooleanInput source="show" label="Mostra al pubblico" />

          <NumberInput required source="prezzo" label="Prezzo" />

          <ImageInput
            accept="image/*"
            maxSize={20 * 1024 * 1024}
            source="images.image"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            accept="image/*"
            maxSize={20 * 1024 * 1024}
            multiple
            source="images.aux"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Edit>
    );
}