import { useRecordContext } from "react-admin"
import {Typography} from '@mui/material';
import { useLangContext } from './LangContext';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 800,
    },
  });

export const LangListField = ({source, ...props}: any) => {

    const record = useRecordContext();
    const langContext = useLangContext();

    let data
    if(Array.isArray(record[source])) data = record[source]
    else if(typeof record[source] === 'string') data = [{lang: langContext.lang, description: record[source]}]
    else data = [{lang: langContext.lang, description: '--nessuna traduzione disponibile--'}]

    return <CustomWidthTooltip title={
        data.map((e: any) => <p>{`${e.lang}: ${e.description}`}</p>)
    }>
        <Typography {...props} dangerouslySetInnerHTML={{__html: langContext.getTranslation(data)}}></Typography>
    </CustomWidthTooltip>
}