import { FunctionField, Show, TextField } from "react-admin";
import { ImageAuxSourceField, ImageSourceField } from "../../ImageSource";

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LangField } from "../../LangField";
import { useLangContext } from "../../LangContext";


export const MaterialShow = () => {
    const lang = useLangContext();

    return <Show>
        <Container maxWidth="xl" style={{padding: "3rem"}}>
            <Grid container>
                <Grid item xs={12} md={8} lg={9}>
                    <Stack>
                        <FunctionField source="id" variant="h4" render={(record: any) => lang.getTranslation(record.name, "") } />
                        <div><TextField variant="overline" source="type"/> - <FunctionField variant="overline" render={(record: any) => lang.getTranslation(record.origin, "")}/></div>

                        <FunctionField source="available_finishings" variant="h6" render={(record: any) => lang.getTranslation(record.available_finishings, "") } />

                        <Typography variant='h5' style={{paddingTop: '3rem'}}>Descrizione</Typography>
                        <LangField source="description"/>
                        
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <ImageSourceField source={"images.image"} res={"src"} width={"100%"}/>
                    <ImageAuxSourceField source={"images.aux"} res={"src"} width={"100%"}/>
                </Grid>
            </Grid>
            
        </Container>
    </Show>
};