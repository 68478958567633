import { Box, FormLabel, } from '@mui/material'
import { Show, TextField, SimpleShowLayout, ArrayField, Datagrid, EditButton, ReferenceField, ReferenceArrayField } from 'react-admin'
export const CompanyShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField source="ragione_sociale" label="Ragione Sociale" style={{ fontWeight: "bold", fontSize: 32 }} />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box>
                        <FormLabel>Pec: </FormLabel>
                        <TextField source="pec" />
                    </Box>
                    <Box>
                        <FormLabel>P. Iva: </FormLabel>
                        <TextField source="vat_code" />
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Indirizzo:</FormLabel>
                        <TextField source="address" />
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Luogo:</FormLabel>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <TextField source="city" />
                            <Box>
                                <TextField source="cap" />,
                            </Box>
                        </Box>
                        <TextField source="province" />
                        <TextField source="country" />
                    </Box>

                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormLabel>Telefono:</FormLabel>
                        <TextField source="telephone" />
                    </Box>
                </Box>
                <Box>
                    <FormLabel style={{ fontWeight: "bold", fontSize: 32, color: "black" }}>Utenti</FormLabel>
                    <ArrayField source='users' >
                        <Datagrid>
                            <Box>
                                <FormLabel>Email: </FormLabel>
                                <TextField source='email' />
                            </Box>
                            <Box>
                                <FormLabel>Nome: </FormLabel>
                                <TextField source='name' />
                            </Box>
                            <Box>
                                <FormLabel>Cognome: </FormLabel>
                                <TextField source='surname' />
                            </Box>
                        </Datagrid>
                    </ArrayField>
                </Box>


            </SimpleShowLayout>
        </Show>
    )
}