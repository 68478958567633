import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const FloorCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          required
          source="numero_pavimento"
          label="Numero Pavimento"
        />
          <ReferenceInput source="cassa.id" isRequired reference="cassa">
            <AutocompleteInput
              label="Cassa"
              isRequired
              optionText="id"
              filterToQuery={(q) => ({ q_Lid: q })}
            />
          </ReferenceInput>
        <ReferenceInput isRequired source="bancata" reference="branch">
          <SelectInput label="Bancata" optionText="id" />
        </ReferenceInput>
        <ReferenceInput source="materiale" reference="material">
          <AutocompleteInput
            label="Materiale"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="finishing" reference="finishing">
          <AutocompleteInput
            source="finishing"
            label="Finitura"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="quality" reference="quality">
          <AutocompleteInput
            source="quality"
            label="Qualità"
            optionText="id"
            filterToQuery={(q) => ({ q_Lid: q })}
          />
        </ReferenceInput>
        <TextInput source="selection" label="Selezione" />
        <NumberInput isRequired source="formato_1" />
        <NumberInput isRequired source="formato_2" />
        <NumberInput isRequired source="spessore" />
        <NumberInput isRequired source="quantity" />

        <BooleanInput source="isFreeLength" label="Pavimento a correre" />
        <NumberInput source="mq" label="Mq" />
        <TextInput source="tipoFormato" label="Tipo Formato" />
        <BooleanInput source="show" label="Visibile" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};
