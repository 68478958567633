import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from "../providers/authProvider";
import provider from "../providers/provider";
import LoginPage from "./auth/LoginPage";
import { BlockCreate } from "./views/create/BlockCreate";
import { FinishingCreate } from "./views/create/FinishingCreate";
import { MaterialCreate } from "./views/create/MaterialCreate";
import { ProjectCreate } from "./views/create/ProjectCreate";
import { SlabCreate } from "./views/create/SlabCreate";
import { BlockList } from "./views/list/BlockList";
import { FinishingList } from "./views/list/FinishingList";
import { MaterialList } from "./views/list/MaterialList";
import { ProjectList } from "./views/list/ProjectList";
import { SlabList } from "./views/list/SlabList";
import { MaterialShow } from "./views/show/MaterialShow";
import { ProjectShow } from "./views/show/ProjectShow";

import GavelIcon from '@mui/icons-material/Gavel';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
import BookIcon from '@mui/icons-material/Book';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TileIcon from '@mui/icons-material/Square';
import StoreIcon from '@mui/icons-material/Store';
import { FinishingEdit } from "./views/edit/FinishingEdit";
import { MaterialEdit } from "./views/edit/MaterialEdit";
import { SlabEdit } from "./views/edit/SlabEdit";
import { ProjectEdit } from "./views/edit/ProjectEdit";
import { QualityList } from "./views/list/QualityList";
import { QualityCreate } from "./views/create/QualityCreate";
import { QualityEdit } from "./views/edit/QualityEdit";
import { HealthStateList } from "./views/list/HealthStateList";
import { HealthStateCreate } from "./views/create/HealthStateCreate";
import { HealthStateEdit } from "./views/edit/HealthStateEdit";
import { AppLayout } from "./AppLayout";
import { useLangContext } from "./LangContext";
import { NewsCreate } from "./views/create/NewsCreate";
import { NewsList } from "./views/list/NewsList";
import { NewsEdit } from "./views/edit/NewsEdit";
import { BlockEdit } from "./views/edit/BlockEdit";
import { FloorList } from "./views/list/FloorList";
import { FloorCreate } from "./views/create/FloorCreate";
import { FloorEdit } from "./views/edit/FloorEdit";
import { InertList } from "./views/list/InertList";
import { InertCreate } from "./views/create/InertCreate";
import { InertEdit } from "./views/edit/InertEdit";
import { Label, Square } from "@mui/icons-material";
import { LavoratoList } from "./views/list/LavoratoList";
import { LavoratoCreate } from "./views/create/LavoratoCreate";
import { LavoratoEdit } from "./views/edit/LavoratoEdit";
import { MachineryList } from "./views/list/MachineryList";
import { MachineryCreate } from "./views/create/MachineryCreate";
import { MachineryEdit } from "./views/edit/MachineryEdit";
import { CassaList } from "./views/list/CassaList";
import { CassaCreate } from "./views/create/CassaCreate";
import { CassaShow } from "./views/show/CassaShow";
import { CassaEdit } from "./views/edit/CassaEdit";
import { UserList } from "./views/list/UserList";
import { UserEdit } from "./views/edit/UserEdit";
import { UserShow } from "./views/show/UserShow";
import { CompanyList } from "./views/list/CompanyList";
import { CompanyShow } from "./views/show/CompanyShow";
import { CompanyEdit } from "./views/edit/CompanyEdit";
import { RoleTypesList } from "./views/list/RoleTypeList";
import { RoleTypesEdit } from "./views/edit/RoleTypeEdit";
import { RoleTypesCreate } from "./views/create/RoleTypeCreate";
import { CourseCreate } from "./views/create/CourseCreate";
import { CourseList } from "./views/list/CourseList";
import { CourseEdit } from "./views/edit/CourseEdit";

const App = () => {

  const [lang, setLang] = React.useState("it")
  const langContext = useLangContext()
  langContext.lang = lang
  langContext.setLang = setLang
  langContext.getTranslation = (obj: { lang: string, description: string }[], dflt?: string) => {
    if (!Array.isArray(obj)) return dflt || "--nessuna traduzione--"
    const data = obj.filter(e => e.lang === lang)
    if (data.length > 0) return data[0].description || dflt || "--nessuna traduzione--"
    return dflt || "--nessuna traduzione--"
  }

  return (
    <>
      <Admin
        dataProvider={provider}
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={AppLayout}
        requireAuth
      >
        <Resource
          icon={GavelIcon}
          name="finishing"
          list={FinishingList}
          create={FinishingCreate}
          edit={FinishingEdit}
        />
        <Resource
          icon={GavelIcon}
          name="quality"
          list={QualityList}
          create={QualityCreate}
          edit={QualityEdit}
        />
        <Resource
          icon={GavelIcon}
          name="healthstate"
          list={HealthStateList}
          create={HealthStateCreate}
          edit={HealthStateEdit}
        />
        <Resource
          icon={BookIcon}
          name="material"
          list={MaterialList}
          create={MaterialCreate}
          show={MaterialShow}
          edit={MaterialEdit}
        />
        <Resource
          icon={ViewArrayIcon}
          name="inert"
          list={InertList}
          create={InertCreate}
          edit={InertEdit}
        />
        <Resource
          icon={ViewArrayIcon}
          name="block"
          list={BlockList}
          create={BlockCreate}
          edit={BlockEdit}
        />
        <Resource
          name="slab"
          list={SlabList}
          create={SlabCreate}
          edit={SlabEdit}
        />

        <Resource
          icon={AccountTreeIcon}
          name="project"
          list={ProjectList}
          create={ProjectCreate}
          show={ProjectShow}
          edit={ProjectEdit}
        />
        <Resource
          icon={ViewArrayIcon}
          name="news"
          list={NewsList}
          create={NewsCreate}
          edit={NewsEdit}
        />
        <Resource
          icon={TileIcon}
          name="cassa"
          list={CassaList}
          create={CassaCreate}
          edit={CassaEdit}
          show={CassaShow}
        />
        <Resource
          icon={TileIcon}
          name="floor"
          list={FloorList}
          create={FloorCreate}
          edit={FloorEdit}
        />
        {/* <Resource
        icon={Square}
        name="lavorati"
        list={LavoratoList}
        create={LavoratoCreate}
        edit={LavoratoEdit}
        /> */}
        <Resource
          icon={TileIcon}
          name="machinery"
          list={MachineryList}
          create={MachineryCreate}
          edit={MachineryEdit}
        />
        <Resource
          icon={GroupIcon}
          name="user"
          list={UserList}
          edit={UserEdit}
          show={UserShow}
        />
        <Resource
          icon={StoreIcon}
          name="company"
          list={CompanyList}
          show={CompanyShow}
          edit={CompanyEdit}
        />
        <Resource
          icon={GavelIcon}
          name="role-types"
          list={RoleTypesList}
          edit={RoleTypesEdit}
          create={RoleTypesCreate}
        />
        <Resource
          icon={GavelIcon}
          name="course"
          list={CourseList}
          edit={CourseEdit}
          create={CourseCreate}
        />
      </Admin>
    </>
  );
};

export default App;
