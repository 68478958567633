import { Datagrid, FunctionField, List, NumberInput, TextField, TextInput } from "react-admin";
import { ImageSourceField } from "../../ImageSource";
import { LangListField } from "../../LangListField";
import Chip from "@mui/material/Chip"

const filters = [
    <TextInput label="Search" source="q_title" alwaysOn />,
    <NumberInput label="Id" source="q_Lid" alwaysOn />,
]

export const ProjectList = (props: any) => 
    <List {...props} filters={filters}>
        <Datagrid rowClick="show">
            <ImageSourceField source="images.image" res="thumb" height="100px" label="Immagine"/>
            <LangListField source="title" label="Titolo"/>
            <LangListField source="location" label="Località"/>
            <LangListField source="nation" label="Nazione"/>
            <FunctionField source="service" label="Service" render={(r: any) => {return <>{r.service.map((e: string) => <Chip label={e}/>)}</>}}/>
            <TextField source="ord_key"/>
        </Datagrid>
    </List>