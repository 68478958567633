import { Create, Edit, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from "react-admin"

export const CassaEdit = (props: any) => {
    return (
      <Edit {...props}>
        <SimpleForm>
          <TextInput disabled source="id" label="Id Cassa" />
          <TextInput required source="posizione" label="Posizione" />
          <NumberInput
            required
            source="prezzo"
            label="Prezzo"
            defaultValue={0}
          />
          <ImageInput
            accept="image/*"
            maxSize={20 * 1024 * 1024}
            source="images.main_image"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            accept="image/*"
            maxSize={20 * 1024 * 1024}
            multiple
            source="images.aux"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Edit>
    );
}