import Map, { Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import { useState } from "react";
import { useController } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export const MapInput = ({source, label}: any) => {

    const lat = useController({ name: source + '.lat', defaultValue: '0' });
    const lng = useController({ name: source + '.lng', defaultValue: '0' });
    
    const [selected, setSelected] = useState<{lng: number, lat: number}>({
        lat: parseFloat(lat.field.value) || 0,
        lng: parseFloat(lng.field.value) || 0
    });

    return <>
        <Map 
            mapLib={maplibregl}
            onDblClick={(e) => {
                setSelected(e.lngLat)
                lat.field.onChange({
                    target: {
                        value: e.lngLat.lat
                    }
                })
                lng.field.onChange({
                    target: {
                        value: e.lngLat.lng
                    }
                })
            }}
            initialViewState={{
                longitude: selected.lat,
                latitude: selected.lng,
                zoom: 3.5
            }}
            style={{width: 600, height: 400}}
            mapStyle="https://demotiles.maplibre.org/style.json"
        >
            {!!selected && <Marker longitude={selected.lng} latitude={selected.lat}/>}
        </Map>
        <TextField
            {...lat.field}
            label={(label||"") + " LAT"}
            error={(lat.fieldState.isTouched || lat.formState.isSubmitted) && lat.fieldState.invalid}
            helperText={(lat.fieldState.isTouched || lat.formState.isSubmitted) && lat.fieldState.invalid ? lat.fieldState.error?.message : ''}
            onChange={(e) => {
                if(!isNaN(parseFloat(e.target.value)))
                    setSelected({
                        lat: parseFloat(e.target.value),
                        lng: selected?.lng || 0
                    })  
                lat.field.onChange(e)
            }}
            value={selected.lat}
        />
        <TextField
            {...lng.field}
            label={(label||"") + " LNG"}
            error={(lng.fieldState.isTouched || lng.formState.isSubmitted) && lng.fieldState.invalid}
            helperText={(lng.fieldState.isTouched || lng.formState.isSubmitted) && lng.fieldState.invalid ? lng.fieldState.error?.message : ''}
            onChange={(e) => {
                if(!isNaN(parseFloat(e.target.value)))
                    setSelected({
                        lng: parseFloat(e.target.value),
                        lat: selected?.lat || 0
                    })  
                lng.field.onChange(e)

            }}
            value={selected.lng}
        />
    </>
}