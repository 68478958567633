import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"

export const RoleTypesCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm>
                <LangInput source="name" label="Nome">
                    <TextInput source="description" required  />
                </LangInput>

                <NumberInput source="order" label="Ordine" />
                <BooleanInput source="visible" label="Visibile" defaultValue={true} />
                <BooleanInput source="isPrivateType" label="Private?" defaultChecked={false} />

            </SimpleForm>

        </Create>
    )

}