import {  Edit, SimpleForm, TextInput } from "react-admin"

export const HealthStateEdit = (props: any) => {

    return <Edit {...props}>
        <SimpleForm>
        <TextInput source="id" required/>
        <TextInput source="description" required/>
        </SimpleForm>
    </Edit>
}