import * as React from 'react';
import { useRecordContext } from "react-admin"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import purify from "dompurify"


export const LangField = ({source}: any) => {

    const record = useRecordContext();

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };
  

    console.log(record[source])
    return (
      <div style={{paddingTop: "1rem", paddingBottom: "1rem"}}>
        {record[source] && typeof(record[source]) === "string" && <Typography variant="body2" component="span" >
                    {!record[source] ? "Nessuna descrizione fornita." : 
                        <span
                            dangerouslySetInnerHTML={{
                                __html: purify.sanitize(record[source]),
                            }}
                        />
                    }
                </Typography>}

        {record[source] && Array.isArray(record[source]) && record[source].map((e: any) => <Accordion expanded={expanded === e.lang} onChange={handleChange(e.lang)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Lingua: {e.lang}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2" component="span" >
                    {!e.description ? "Nessuna descrizione fornita." : 
                        <span
                            dangerouslySetInnerHTML={{
                                __html: purify.sanitize(e.description),
                            }}
                        />
                    }
                </Typography>
            </AccordionDetails>
        </Accordion>)}
      </div>)
}