import { HttpError } from "react-admin";

const authProvider = {
    login: async (params: any) => {
        if (!params.code || !params.state) {
            window.location.href = "/api/auth/login"
            return; // Do not return anything, the login is still loading
        }
        return Promise.resolve();
    },

    checkError: async (error: any) => {
        if([401, 403].includes(error.status || 0))
            await fetch("/api/auth/logout")
    },

    checkAuth: async (params: any)=>{
        const res = await fetch("/api/auth/token")
        if(res.status === 200) return Promise.resolve()
        return Promise.reject()
    },

    logout: async () => {
        await fetch("/api/auth/logout")
    },

    getIdentity: async () => {
        const res = await fetch("/api/auth/token")
        if(res.status !== 200) throw new HttpError("Not logged in", res.status)
        const data = await res.json();

        return {
            id: data.data.userId,
            fullName: data.data.email
        }
    },

    getPermissions: () => Promise.resolve(),
}

export default authProvider;