export async function updateImages(images: any, previous_images: any, paths: any){

    if (!previous_images) previous_images = {}
    if (!images) images = {}


    //step1: deleted fixed images
    await Promise.all([...Object.keys(previous_images).map(async (e) => {
        if (e === 'aux') return;
        if (images[e]) return;
        console.log("DELETE", previous_images[e].src)
        await fetch(previous_images[e].src, {method: "DELETE"})
    }),

    

    //step2: create or update existing ones
    ...Object.keys(images).map(async (e) => {
        if (e === 'aux') return;
        if (!images[e] || !images[e].rawFile) return;

        if(previous_images[e] && previous_images[e].src)
           await fetch(previous_images[e].src, {method: "DELETE"})

        const body  = new FormData();
        const ext = images[e].rawFile.name.substring(images[e].rawFile.name.lastIndexOf("."))
        console.log("PUT " + paths[e].path, paths[e].name + ext)

        body.append(paths[e].name + ext, images[e].rawFile)
        await fetch(paths[e].path, {method: "PUT", body})
    })])

    if(!paths.aux) return
    if(!images.aux) images.aux = []
    if(!previous_images.aux) previous_images.aux = []

    //step3: normalize aux
    const prev_aux = previous_images.aux.reduce((a: any, b: any) => {
        a[b.src.split('/').pop()] = b
        return a
    }, {})

    const aux = images.aux.reduce((a: any, b: any) => {
        a[b.rawFile ? b.rawFile.name : b.src.split('/').pop()] = b
        return a
    }, {})
    const pths: any = {}
    Object.keys(prev_aux).forEach(e => pths[e] = {path: paths.aux, name: e.substring(0, e.lastIndexOf('.'))})
    Object.keys(aux).forEach(e => {
        if(!pths[e]) pths[e] = {path: paths.aux, name: e.substring(0, e.lastIndexOf('.'))}
    })

    await updateImages(aux, prev_aux, pths)
}