import { BooleanField, Datagrid, EditButton, List, NumberField, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { LangField } from "../../LangField";
import { LangChipField } from "../../LangChipField";

const filters = [
    <TextInput label="Search" source="q_Lname" alwaysOn />
]

export const RoleTypesList = (props: any) =>
    <List {...props} filters={filters}>
        <Datagrid rowClick={"edit"}>
            <TextField source="id" label="Id" />
            <LangListField source="name" label="Titolo" />
            <TextField source="order" label="Order" />
            <TextField source="visible" label="Visible" />
            <EditButton />
        </Datagrid>
    </List>