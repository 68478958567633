import { AutocompleteInput, Datagrid, EditButton, FunctionField, List, ReferenceInput, TextField, TextInput } from "react-admin";
import { CheckboxField } from "../../CheckboxField";

const filters = [
  <TextInput label="Search" source="q_Lid" alwaysOn />,
  <ReferenceInput source="q_LmaterialeId" reference="material" alwaysOn perPage={1000000}>
    <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
  </ReferenceInput>,
  <TextInput source="q_Lbancata" label="Bancata" alwaysOn />,

];

export const BlockList = (props: any) => {

  return <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" label="Codice Lastra" />
      <TextField source="position" label="Posizione" />
      <TextField source="materiale.id" label="Materiale" />

      <TextField source="lung_A" label="Lunghezza A" />
      <TextField source="lung_B" label="Lunghezza B" />
      <TextField source="altezza" label="Altezza" />
      <TextField source="larghezza" label="Larghezza" />
      <TextField source="bancata.id" label="Bancata" />

      <TextField source="SR.description" label="Difetti" />
      <FunctionField label="Prezzo" render={(record: any) => `${record.prezzo} €`} />
      <CheckboxField source="show" />

      <EditButton />
    </Datagrid>
  </List>
}