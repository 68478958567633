import { Datagrid, EditButton, List, AutocompleteInput, ReferenceInput, SelectInput, TextField, TextInput, FunctionField, useDataProvider } from "react-admin";
import { CheckboxField } from "../../CheckboxField";
import { useLangContext } from "../../LangContext";

export const SlabList = (props: any) => {

  const filters = [
    // <AutocompleteInput label="Blocco" source="q_blockId" choices={{}} optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />,
    <TextInput source="q_blockId" label="Blocco" alwaysOn />,
    // <TextInput source="q_LmaterialeId" label="Materiale" alwaysOn/>,
    <ReferenceInput source="q_=materiale__id" reference="material" alwaysOn perPage={1000000}>
      <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
    </ReferenceInput>,
    <ReferenceInput source="q_=finishing__id" reference="finishing" alwaysOn perPage={1000000}>
      <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
    </ReferenceInput>,
    <TextInput label="Posizione" source="q_Lposition" alwaysOn />,
    <SelectInput source="q_=quality" label="Qualità" choices={[{ id: "Standard", name: "Standard" }, { id: "Extra", name: "Extra" }, { id: "I° sc.", name: "I° sc." }, { id: "II° sc.", name: "II° sc." }]} alwaysOn />,
    <SelectInput source="q_=show" label="Mostra" choices={[{ id: "bool:true", name: "SI" }, { id: "bool:false", name: "NO" }]} alwaysOn />
  ]

  const lang = useLangContext()

  return (
    <List {...props} filters={filters}>
      <Datagrid>

        <TextField source="id" label="Codice Lastra" />
        <TextField source="finishing.id" label="Finitura" />
        <TextField source="position" label="Posizione" />
        <FunctionField
          label="Qualità"
          render={(r: any) => lang.getTranslation(r.quality?.description)}
        />
        <TextField source="blockId" label="Blocco" />
        <TextField source="materiale.id" label="Materiale" />

        <TextField source="lung_A" label="Lunghezza A" />
        <TextField source="lung_B" label="Lunghezza B" />
        <TextField source="altezza" label="Altezza" />
        <TextField source="spessore" label="Spessore" />
        <TextField source="bancata.id" label="Bancata" />

        <TextField source="SR.description" label="Difetti" />


        <FunctionField
          label="Prezzo"
          render={(record: any) => `${record.prezzo} €`}
        />
        <CheckboxField source="show" />

        <EditButton />
      </Datagrid>
    </List>
  );
}