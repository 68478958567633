import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, DeleteResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const courseProvider = {
  ...base_provider,
  create: async (resource: any, params: any): Promise<CreateResult<any>> => {
    const data = params.data;

    const res = await fetch(`/api/course`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        description: JSON.stringify(data.description),
        name: JSON.stringify(data.name)
      }),
    });

    if (res.status !== 200) {
      throw new Error(`http error: ${res.status}`);
    }

    const { id } = await res.json();

    if (params.data.main_image) {
      const body = new FormData();
      body.append(
        id +
        params.data.main_image.title.substring(
          params.data.main_image.title.lastIndexOf(".")
        ),
        params.data.main_image.rawFile
      );
      fetch(`/api/images/course/${id}/main`, { method: "PUT", body }).catch((e) => {
        throw e;
      });
    }
    if (params.data.images) {
      await Promise.all(
        params.data.images.map((img: any) => {
          const body = new FormData();
          body.append(img.title, img.rawFile);
          return fetch(`/api/images/course/${id}`, {
            method: "PUT",
            body,
          }).catch((e) => {
            throw e;
          });
        })
      );
    }


    return { data: { id: params.data.id } };
  },
  update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

    updateImages(params.data.images, params.previousData.images, {
      main_image: { path: `/api/images/course/${params.data.id}/main`, name: params.data.id },
      aux: `/api/images/course/${params.data.id}/`
    })

    await new Promise((res) => setTimeout(res, 500));
    delete params.data.images;

    const data = params.data;
    const res = await fetch(`/api/course/${params.previousData.id}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        description: JSON.stringify(data.description),
        name: JSON.stringify(data.name)
      }),
    });

    if (res.status !== 200) {
      let body = await res.json();
      throw new HttpError(body.message, res.status, "");
    }

    return { data: Object.assign(params.data, { id: params.previousData.id }) };
  },

  delete: async (resource: any, params: any): Promise<DeleteResult<any>> => {
    updateImages([], params.previousData.images, {
      image: { path: `/api/images/course/${params.id}/main`, name: params.id },
      aux: `/api/images/course/${params.id}/`
    })

    await new Promise((res) => setTimeout(res, 500));

    delete params.previousData.images

    const res = await fetch(`/api/course/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: params.id,
      })
    })

    if (res.status !== 200) {
      let body = await res.json();
      throw new HttpError(body.message, res.status, "");
    }
    return { data: Object.assign(params.data, { id: params.previousData.id }) }
  }
}