import { AutocompleteInput, Datagrid, EditButton, List, ReferenceInput, TextField, TextInput } from "react-admin";
import { CheckboxField } from "../../CheckboxField";

const filters = [
  <ReferenceInput source="q_Lid" reference="floor" alwaysOn perPage={1000000}>
    <AutocompleteInput label="Floor" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
  </ReferenceInput>,
  <ReferenceInput source="q_LmaterialeId" reference="material" alwaysOn perPage={1000000}>
    <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({ q_Lid: q })} />
  </ReferenceInput>,
  <TextInput label="Bancata" source="q_Lbancata" alwaysOn />,
]

export const FloorList = (props: any) => {

  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <TextField source="id" label="Codice Pavimento" />
        <TextField source="numero_pavimento" label="N. Pavimento" />
        <TextField source="cassa.id" label="Cassa" />
        <TextField source="formato_1" label="Formato 1" />
        <TextField source="formato_2" label="Formato 2" />
        <TextField source="spessore" label="Spessore" />
        <TextField source="quantity" label="Quantità" />
        <TextField source="mq" label="Mq" />
        <CheckboxField source="show" />
        <CheckboxField source="isFreeLength" />

        <EditButton />
      </Datagrid>
    </List>
  );
}