import { Datagrid, List, TextField, TextInput } from "react-admin"

const filters = [
    <TextInput label="Ragione Sociale" source="q_Lragione_sociale" alwaysOn />,
    <TextInput label="Partita Iva" source="q_Lvat_code" alwaysOn />,
    <TextInput label="Paese" source="q_Lcountry" alwaysOn/>,

]

export const CompanyList = (props: any) => {
    return (
        <List {...props} filters={filters}>
            <Datagrid rowClick={"show"}>
                <TextField source="ragione_sociale" label="Ragione Sociale"/>
                <TextField source="vat_code" label="Partita Iva"/>
                <TextField source="country" label="Paese"/>
            </Datagrid>
        </List>
    )
}